<template>
  <v-dialog v-model="visible" max-height="500" max-width="700">
    <v-card>
      <v-toolbar dense color="gray" dark>
        <v-toolbar-title class="title" justify="center">Neue Pilzarten</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-content">
        <v-form @submit.prevent="submitForm">
          <v-row>
            <v-col cols="5">
              <label for="name:">Abkürzung</label>
              <v-text-field class="textFieldOne" outlined v-model="mushroom.abbreviation" :rules="rules.abbreviation"
                @input="abbreviationInput"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label for="name:">Deutsch</label>
              <v-text-field class="textFieldTwo" outlined v-model="mushroom.germanName"
                :rules="rules.germanName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label for="name:">Latein</label>
              <v-text-field class="textFieldThree" outlined v-model="mushroom.latinName"
                :rules="rules.latinName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-btn type="submit" class="emptyForm" style="margin-left:95px; max-width:360px; color:black;"
              :loading="isLoading" color="primary" :disabled="disableErstellen">Erstellen
            </v-btn>
            <v-btn type="button" class="emptyForm" style="margin-left:25px;" :loading="isLoading" color="gray"
              @click="closeModal">Abbrechen</v-btn>
          </v-row>
        </v-form>
        <v-alert type="success" elevation="20" v-show="showAlert" :color="alertColor" class="alert">
          {{ alertText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import UpdateMushroomType from "./UpdateMushroomType";

export default {
  props: {
    mushroom: {
      type: Object,
      default: () => ({
        id: null,
        abbreviation: "",
        germanName: "",
        latinName: ""
      })
    },
    visible: Boolean,
    itemList: Array
  },
  data() {
    return {
      disableErstellen: true,
      isLoading: false,
      dialog: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      rules: {
        abbreviation: [
          v => {
            if (!v) return true; // Allow empty input
            if (v.length <= 4) {
              return true;
            } else {
              return "Die Abkürzung darf höchstens 4 Zeichen umfassen";
            }
          },
          v => {
            if (!v) return true; // Allow empty input
            if (v.length >= 4) {
              return true;
            } else {
              return "Die Abkürzung muss mindestens 4 Zeichen enthalten";
            }
          }
        ],
        germanName: [v => !v || !!v || "Deutscher Name ist erforderlich"],
        latinName: [v => !v || !!v || "Lateinischer Name ist erforderlich"]
      },
    };
  },
  computed: {
    formTitle() {
      return this.mushroom && this.mushroom.id
        ? "mushroom bearbeiten"
        : "Neue mushroom";
    },
    validForm() {
      return (
        !!this.mushroom.germanName &&
        !!this.mushroom.latinName &&
        !!this.mushroom.abbreviation
      );
    }
  },
  components: {
    UpdateMushroomType
  },
  methods: {
    abbreviationInput() {
      const inputValue = this.mushroom.abbreviation;

      if (inputValue.length == 4) {
        this.disableErstellen = false;
      } else {
        this.disableErstellen = true;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    showUpdateDialog() {
      this.dialog = true;
    },
    submitForm() {
      this.isLoading = true;

      const requestBody = {
        abbreviation: this.mushroom.abbreviation,
        germanName: this.mushroom.germanName,
        latinName: this.mushroom.latinName
      };

      if (!this.mushroom.id) {
        axios
          .post(
            "https://tilia-ch.rrota.org/api/MushroomType/Create",
            requestBody
          )
          .then(response => {
            this.isLoading = false;
            if (Array.isArray(this.itemList)) {
              this.$set(this.itemList, this.itemList.length, response.data);
            } else {
              this.itemList = [response.data];
            }
            if (response.status === 200) {
              this.showAlert = true;
              this.alertColor = "success";
              this.alertText = "Das neue Pilzarten wurde erfolgreich erstellt";
              this.$emit("updated-table", true);
              setTimeout(() => {
                this.closeModal();
                this.showAlert = false;
              }, 2000);
            }
          })
          .catch(error => {
            console.log(error);
            this.showAlert = true;
            this.isLoading = false;
            this.alertColor = "error";
            this.alertText = "Fehler beim Erstellen der Pilzarten";
            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
          });
      }
    }
  }
};
</script>
<style>
.alert {
  position: fixed !important;
  width: 100% !important;
  max-width: 784px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px 100px !important;
}

.form-content {
  margin-top: 60px;
}
</style>
